import React from 'react';

import { ExperiencePage } from '@kali/theme/lib/pages/experience/page';
import Layout from '../components/layout';

export default function ExperienceRoute() {
  return (
    <Layout title="Experience">
      <ExperiencePage />
    </Layout>
  );
}
